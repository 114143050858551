<template>
    <ul class="job-lists">
        <li v-for="(job,ind) in jobsData" :key="ind">
            <label class="control control-checkbox all-checkbox" v-if="isAll(job)">
                {{job.name}}
                    <input type="checkbox" v-model="jobs" :value="job" @change="selectJobs($event,job)"/>
                <div class="control_indicator"></div>
            </label>
            <label class="control control-checkbox" v-else v-bind:class="{'disabled':isAllSelected}">
                {{job.name}}
                    <input type="checkbox" v-model="jobs" :value="job" @change="selectJobs($event,job)"/>
                <div class="control_indicator"></div>
            </label>
        </li>
    </ul>
    <!-- v-model="profile.jobs" -->
</template>

<script>

export default {
    name:'JobLists',
    props:['jobsData','selectedJobs','category'],
    data(){
        return{
             isAllSelected:false,
             jobs:this.selectedJobs
        }
    },

    created(){
        if (this.jobs && this.jobs.length > 0) {this.jobs.forEach(job => {
            // console.log(job.catgeory,)
            if(job.catgeory == this.category && this.isAll(job)){
                this.isAllSelected=true;
            }
        });
    }
    },
    methods:{
        isAll(job){
            return (job.name.indexOf('All')!=-1 && job.name.length == 3) ? true:false;
        },
        selectJobs(e,job){
            let parent = e.target.parentNode;
            let checkbox = e.target;
            parent.classList.toggle('active');
            this.jobs = this.jobs.filter(selected=>selected!=job);
            if(checkbox.checked && this.jobs.length <= 9){
                if(this.isAll(job)){
                    this.isAllSelected = true;
                    this.jobs = this.jobs.filter(selected=>selected.catgeory!=job.catgeory);
                    this.jobs.push(job);
                }else{
                    this.jobs.push(job);
                }
                
            }else{
                    if(this.isAll(job)){
                        this.isAllSelected = false;
                    }
                checkbox.checked = false
            }
            this.jobs.sort(function(a,b){
                return a.name.localeCompare(b.name);
            });
            
        },
        
    },
    watch:{
        jobs:function(val){
             this.$emit("jobSelection",val);
        },
        selectedJobs:function(val){
            this.jobs = val;
        }
    }
}
</script>

<style lang="scss" scoped>
ul.job-lists {
        list-style: none;
        display: none;
        padding: 0 1rem;
}
ul.job-ctg li.active ul.job-lists{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    // width: 32rem;
    flex-wrap: wrap;
}
ul.job-lists li {
    width: 50%;
}

label {
    font-weight: 500;
    color: #333;
    font-size: 14px;
}
label.control.control-checkbox.disabled {
    opacity: 0.3;
    pointer-events: none;
}
/* Checkbox Styles */
.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 14px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background:#e0dddd;
    border: 0px solid #000000;
    border-radius: 0px;
    pointer-events: none;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #cccccc;
}

.control input:checked ~ .control_indicator {
    background: #D22D89;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #e6647d;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    margin-left: -0.9rem;
    margin-top: -0.9rem;
    background: #D22D89;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}
@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
   0% {
       transform: scale(0);
    }
   30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}
label.control.control-checkbox.all-checkbox {
    margin-bottom: 1rem;
}
label.control.control-checkbox.all-checkbox:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 30%;
    height: 1px;
    background: #ccc;
}
</style>