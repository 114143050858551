<template>
    <div>                                                
    <div class="pj-loading" v-show="!hardSkillsData"><i class="fas fa-circle-notch fa-spin"></i></div>
    <div v-show="hardSkillsData">
    <div class="input-group">
        <label>Skill #1</label>
        <autocomplete
            :key="11"
            input-info='{"type":"hardSkills","skill":"skill_one"}'
            @blur="addCoreSkill"
            v-bind="{'default-value':value.skill_one}"
            :search="hardSkillsSource"
            placeholder="Eg: Machine Learning"
            aria-label="Skill One"
            auto-select
            :get-result-value="getResultValue"
            class="hard-skills"
        ></autocomplete>
    </div>
    <div class="input-group">
        <label>Skill #2</label>
        <autocomplete
            :key="22"
            input-info='{"type":"hardSkills","skill":"skill_two"}'
            @blur="addCoreSkill"
            v-bind="{'default-value':value.skill_two}"
            :search="hardSkillsSource"
            placeholder="Eg: Software Development"
            aria-label="Skill Two"
            auto-select
            :get-result-value="getResultValue"
            class="hard-skills"
            @submit="handleSkillTwo"
        ></autocomplete>
    </div>
    <div class="input-group">
        <label>Skill #3</label>
        <autocomplete
            :key="33"
            input-info='{"type":"hardSkills","skill":"skill_three"}'
            @blur="addCoreSkill"
            v-bind="{'default-value':value.skill_three}"
            :search="hardSkillsSource"
            placeholder="Eg: Strategy"
            aria-label="Skill Three"
            auto-select
            :get-result-value="getResultValue"
            class="hard-skills"
            @submit="handleSkillThree"
        ></autocomplete>
    </div>
    <div class="input-group">
        <label>Skill #4</label>
        <autocomplete
            :key="44"
            input-info='{"type":"hardSkills","skill":"skill_four"}'
            @blur="addCoreSkill"
            v-bind="{'default-value':value.skill_four}"
            :search="hardSkillsSource"
            placeholder="Eg: Social Media"
            aria-label="Skill Four"
            auto-select
            :get-result-value="getResultValue"
            class="hard-skills"
            @submit="handleSkillFour"
        ></autocomplete>
    </div>
    <div class="input-group">
        <label>Skill #5</label>
        <autocomplete
            :key="55"
            input-info='{"type":"hardSkills","skill":"skill_five"}'
            @blur="addCoreSkill"
            v-bind="{'default-value':value.skill_five}"
            :search="hardSkillsSource"
            placeholder="Eg: PPC"
            aria-label="Skill Five"
            auto-select
            :get-result-value="getResultValue"
            class="hard-skills"
            @submit="handleSkillFive"
        ></autocomplete>
    </div>
    <div style="margin-top:2rem">
        <div class="user-form-buttons">
            <button class="btn btn-primary ml-2" @click.prevent="submitHardSkills">Save</button>
        </div>
    </div>
    
    </div>
    </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import {storage} from '../firebase';
export default {
    name:'hardSkills',
    props:['value'],
    data(){
        return{
             hardSkillsData:false,
             isGoingToNextStep:true,
        }
    },
    components: {
        Autocomplete
    },
    created(){
        this.loadHardSkills();
    },
    computed:{
         hardSkills: () =>  {
            return storage.refFromURL('gs://excelerate2020.appspot.com/hardskills.json').getDownloadURL()
        },
    },
    methods:{
        hardSkillsSource(input) {
            let hsds = JSON.parse(JSON.stringify(this.hardSkillsData));
            // console.log(hsds);
            if (input.length < 1) { return [] }
            return hsds.filter(hsd => {
                return hsd.name.toLowerCase()
                .startsWith(input.toLowerCase())
            })
         },
        addCoreSkill(e){
            // console.log(e,e.target.getAttribute('input-info'))
             let interedVal = JSON.parse(e.target.getAttribute('input-info'));
            this.value[interedVal.skill] = e.target.value;
        },
        getResultValue(result) {
            return result.name
        },
        async loadHardSkills(){
            let hardSkillsLink = await this.hardSkills;
            fetch(hardSkillsLink,{
                method:'GET'
            }).then(res=>res.json()).then(result=>{
                this.hardSkillsData= result[0].jobs;
            });
        },
        validateHardSkills(){
            this.isGoingToNextStep = true;
            document.querySelectorAll('.hard-skills').forEach(elem=>{
                if(elem.querySelector('input').value == ''){
                    elem.classList.add('required');
                }else{
                    elem.classList.remove('required');
                }
            })

            document.querySelectorAll('.hard-skills.required').length > 0 ?this.isGoingToNextStep =false:'';
        },
        handleSkillOne(result){
            this.value.skill_one = result.name;
        },
        handleSkillTwo(result){
            this.value.skill_two= result.name;
        },
        handleSkillThree(result){
            this.value.skill_three= result.name;
        },
        handleSkillFour(result){
            this.value.skill_four= result.name;
        },
        handleSkillFive(result){
            this.value.skill_five= result.name;
        },
        submitHardSkills(){
            this.validateHardSkills();
            if(this.isGoingToNextStep){
                this.$emit('updateHardSkills',true);
            }
        }
    },
    watch:{
        value:function(val){
             this.$emit("input",val);
        }
    }
}
</script>

<style scoped>
.input-group div{
    width:100%;
}
.input-group label {
    color: #333;
    font-size: 12px;
}
.user-form-buttons {
    position: absolute;
    right: 50px !important;
    bottom: 40px;
}
</style>