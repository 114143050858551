<template>
    <div>
        <textarea :value="value" :placeholder="placeholder" @input="updateValue($event.target.value)" @keyup="validateCharectors"  :class="{'limit-exceeded':isCharactersLimitExceeded}" rows="12"></textarea>
        <div class="charector-counter">
            Characters {{textLength ? textLength : 0}}/{{ this.limit ? this.limit : 2000 }}
        </div>
    </div>
</template>

<script>
import { toUSVString } from 'util';

export default {
    name:'plainTextEditor',
    props:['value', 'placeholder', 'limit'],
    data(){
        return{
            characters:this.value.length,
            textLength: this.value.length,
            isCharactersLimitExceeded:false,
        }
    },
    // ! the validation was not working for all cases unless initiated on mount and update
    mounted(){
        this.validateCharectors()
    },
    updated(){
        this.validateCharectors()

    },
    methods:{
        updateValue(value){
            this.textLength = value.length
            this.$emit("input",value);
        },
        validateCharectors(){
            this.characters = this.value.length;
            const totalLimit = this.limit ? this.limit : 2000
            if(this.characters > totalLimit){
                this.isCharactersLimitExceeded = true;
            }else{
                this.isCharactersLimitExceeded = false;
            }
            
        }
    }
}
</script>

<style scoped>
textarea {
    border: 1px solid #ccc;
    width: 100%;
    display: block;
    padding: 1rem;
    outline: none;
}
.charector-counter {
    text-align: right;
    font-size: 12px;
    margin-top: 2px;
}
textarea:focus-visible {
    border: 1px solid #ccc;
}
textarea.limit-exceeded {
    border-color: red;
}
</style>